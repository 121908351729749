import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, Snackbar, Alert } from "@mui/material";
import { AgentContext } from "core/contexts";
import { AgentResponse } from "core/interfaces/agent-details.interface";
import { formatValue, mapToAgentRequestData } from "modules/utils";
import { EmailInfo } from "./email-info.component";
import { AgentNotificationRequest } from "core/interfaces/agent-notification-request.interface";
import {
  logAgentService,
  sendPropertyDetailsByEmail,
} from "core/services/agent-services";
import { LabelInfo } from "./inputs";

export function AgentDetails({
  agentData,
}: {
  agentData: AgentResponse;
}): JSX.Element {
  const { propertyDetails } = React.useContext(AgentContext) || {};
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<
    "success" | "error"
  >("success");

  const agentDataRequest: AgentNotificationRequest = mapToAgentRequestData(
    propertyDetails || null,
    agentData
  );

  React.useEffect(() => {
    const logValidation = async (): Promise<void> => {
      if (agentDataRequest) {
        await logAgentService(agentDataRequest);
      }
    };
    logValidation();
  }, [agentDataRequest]);

  const handleYesClick = async (): Promise<void> => {
    if (!propertyDetails) {
      setSnackbarMessage("Property details are missing. Cannot send email.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const success = await sendPropertyDetailsByEmail(agentDataRequest);
    if (success) {
      setSnackbarMessage("Email sent successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate(-1);
      }, 3000);
    } else {
      setSnackbarMessage("Failed to send email. Please try again later.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Box>
      <Box sx={{ mt: 2 }}>
        <Box display={"flex"} justifyContent={"space-around"}>
          <LabelInfo
            label="Commission"
            value={
              propertyDetails && propertyDetails.agentCommission
                ? formatValue(
                    propertyDetails.agentCommission,
                    propertyDetails.agentCommissionType
                  )
                : "N/A"
            }
            sx={{ fontSize: "18px" }}
          />
          <LabelInfo
            label="Seller Concession"
            value={
              propertyDetails && propertyDetails.sellerConcession
                ? formatValue(
                    propertyDetails.sellerConcession,
                    propertyDetails.sellerConcessionType
                  )
                : "N/A"
            }
            sx={{ fontSize: "18px" }}
          />
        </Box>

        <Box gap={2} display={"flex"} justifyContent={"center"} mt={2}>
          <Typography variant="body1" fontFamily={'"Open Sans", sans-serif'}>
            Would you like this information emailed to you?
          </Typography>
          <Button
            variant="contained"
            onClick={handleYesClick}
            sx={{ backgroundColor: "#08f", textTransform: "none" }}
          >
            Yes
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 1 }} display={"flex"} justifyContent={"center"}>
        <EmailInfo />
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={(): void => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={(): void => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
