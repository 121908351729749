import React from "react";
import { MarketType } from "core/enum";
import { PropertyDetails } from "core/interfaces/property-details.interface";
import {
  fetchAgentDetails,
  fetchPropertyDetails,
} from "core/services/agent-services";
import { AgentContext } from "./agent.context";
import { AgentResponse } from "core/interfaces/agent-details.interface";

export const AgentProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [mlsNumber, setMlsNumber] = React.useState("");
  const [market, setMarket] = React.useState<MarketType | "">("");
  const [agentDetails, setAgentDetails] = React.useState<AgentResponse | null>(
    null
  );
  const [propertyDetails, setPropertyDetails] =
    React.useState<PropertyDetails | null>(null);

  const fetchPropertyDetailsHandler = React.useCallback(
    async (mlsNumber: string, market: MarketType) => {
      const details = await fetchPropertyDetails(mlsNumber, market);
      if (details) {
        setPropertyDetails(details);
        return true;
      }
      return false;
    },
    []
  );
  const fetchAgentDetailsHandler = React.useCallback(
    async (mlsId: string, market: MarketType) => {
      const details = await fetchAgentDetails(mlsId, market);
      if (details) {
        setAgentDetails(details);
        return true;
      }
      return false;
    },
    []
  );

  return (
    <AgentContext.Provider
      value={{
        mlsNumber,
        setMlsNumber,
        market,
        setMarket,
        propertyDetails,
        fetchPropertyDetails: fetchPropertyDetailsHandler,
        agentDetails,
        fetchAgentDetails: fetchAgentDetailsHandler,
        setAgentDetails,
      }}
    >
      {children}
    </AgentContext.Provider>
  );
};
