import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export const styles: { [key: string]: SxProps<Theme> } = {
  container: {
    backgroundColor: "#f8d7da",
    color: "#721c24",
    padding: 2,
    borderRadius: 1,
    boxShadow: 1,
  },
  list: {
    paddingLeft: 2,
    marginTop: 2,
    marginBottom: 0,
    listStyleType: "disc",
    color: "#721c24",
  },
};
