import React from "react";
import { Alert, Typography } from "@mui/material";

export function EmailInfo(): JSX.Element {
  return (
    <Alert severity="warning">
      <Typography variant="caption" display="inline-block" mb={0.5}>
        Disclaimer: This reflects a seller’s willingness to consider a
        concession/contribution toward a buyer’s expenses but is not a
        guarantee. Buyer and seller may negotiate a contribution, if any, in
        relation to the purchase contract. Any contribution from the seller may
        be used by the buyer at their discretion for items like closing costs,
        repair expenses, interest rate buy-down etc. This is the latest
        information received and is subject to change. PLEASE CHECK BACK TO
        ENSURE YOU HAVE THE MOST UPDATE INFORMATION.
      </Typography>
    </Alert>
  );
}
