import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export function Header(): JSX.Element {
  return (
    <Box
      sx={{
        margin: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 0,
        mt: 2,
      }}
    >
      <Box
        component="img"
        sx={{ width: "25%", mb: 2 }}
        src="https://homesusastorage.blob.core.windows.net/webimages/SpecDeck-Blue-Logo-TM-optimized.svg"
      />
      <Typography
        variant="h1"
        color="textPrimary"
        fontSize={24}
        fontWeight={"bold"}
        fontFamily={'"Open Sans", sans-serif'}
        marginBottom={1}
      >
        Agent Commissions and Seller Concessions
      </Typography>
      <Typography variant="subtitle2" fontSize={14} fontWeight={"normal"}>
        For realtors interested in selling HomesUSA.com&apos;s MLS listings
      </Typography>
    </Box>
  );
}
