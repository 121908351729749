import React from "react";
import { Typography, Box, SxProps } from "@mui/material";

interface LabelInfoProps {
  label: string;
  value: string;
  sx?: SxProps;
  centerContent?: boolean;
}

export function LabelInfo({
  label,
  value,
  sx,
  centerContent,
}: LabelInfoProps): JSX.Element {
  return (
    <Box
      sx={{
        mt: 1,
        display: "flex",
        gap: 1,
        justifyContent: centerContent ? "center" : "flex-start",
      }}
    >
      <Typography
        variant="caption"
        sx={{ color: "#605c5c", fontWeight: "bold", fontSize: "14px", ...sx }}
      >
        {label}:
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontSize: "14px", color: "#605c5c", ...sx }}
      >
        {value}
      </Typography>
    </Box>
  );
}
