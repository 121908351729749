import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface BackButtonProps {
  onBack: () => void;
}

export function BackButton({ onBack }: BackButtonProps): JSX.Element {
  const navigate = useNavigate();

  const handleBack = (): void => {
    onBack();
    navigate(-1);
  };

  return (
    <Button
      onClick={handleBack}
      sx={{
        color: "white",
        textTransform: "none",
        marginLeft: "10px",
        paddingInline: "15px ",
        backgroundColor: "#012b5b",
        "&:hover": {
          backgroundColor: "#012b5b",
        },
      }}
    >
      Cancel
    </Button>
  );
}
