export enum MarketType {
  Ctx = 1000,
  Houston = 16,
  SanAntonio = 8,
  Austin = 6,
  DFW = 5,
}

export const MarketLabels = new Map<MarketType, string>([
  [MarketType.Austin, "Austin"],
  [MarketType.DFW, "Dallas-Fort Worth"],
  [MarketType.SanAntonio, "San Antonio"],
  [MarketType.Houston, "Houston"],
  [MarketType.Ctx, "CTX"],
]);

export const marketTypeToMarketCode: Map<MarketType, string> = new Map([
  [MarketType.Austin, "austin"],
  [MarketType.DFW, "dfw"],
  [MarketType.SanAntonio, "sanAntonio"],
  [MarketType.Houston, "houston"],
  [MarketType.Ctx, "ctx"],
]);
