import React from "react";
import { Grid } from "@mui/material";
import { LabelInfo } from "core/components";
import { AgentContext } from "core/contexts";

export function PropertyInfo(): JSX.Element {
  const { propertyDetails } = React.useContext(AgentContext) || {};
  return (
    <>
      {propertyDetails && (
        <>
          <Grid item xs={4}>
            <LabelInfo label="MLS #" value={propertyDetails.mlsNumber} />
          </Grid>
          <Grid item xs={4}>
            <LabelInfo
              label="List Status"
              value={propertyDetails.listStatus}
              centerContent={true}
            />
          </Grid>
          <Grid item xs={4}>
            <LabelInfo
              label="Price"
              value={`$ ${propertyDetails?.listPrice?.toString() ?? "N/A"}`}
              centerContent={true}
            />
          </Grid>
          <Grid item xs={4}>
            <LabelInfo
              label="Address"
              value={`${propertyDetails.streetNumber} ${propertyDetails.streetName}`}
            />
          </Grid>
          <Grid item xs={4}>
            <LabelInfo
              label="City"
              value={propertyDetails.city}
              centerContent={true}
            />
          </Grid>
          <Grid item xs={4}>
            <LabelInfo
              label="Zip Code"
              value={propertyDetails.zip}
              centerContent={true}
            />
          </Grid>
          <Grid item xs={4}>
            <LabelInfo
              label="Subdivision"
              value={propertyDetails.subdivision}
            />
          </Grid>
        </>
      )}
    </>
  );
}
