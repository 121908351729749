import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes.component";
import { Header } from "core/components";
import { AgentProvider } from "core/contexts/agent.provider";

export function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Header />
      <AgentProvider>
        <AppRoutes />
      </AgentProvider>
    </BrowserRouter>
  );
}
