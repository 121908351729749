import axios from "axios";
import { MarketType, marketTypeToMarketCode } from "core/enum";
import { AgentResponse } from "core/interfaces/agent-details.interface";
import { AgentNotificationRequest } from "core/interfaces/agent-notification-request.interface";
import { PropertyDetails } from "core/interfaces/property-details.interface";

const migrationUrl =
  process.env.REACT_APP_API_MIGRATION ?? "https://localhost:7050";
const downloanderUrl =
  process.env.REACT_APP_API_DOWNLOADER ?? "https://localhost:7013";
const downloanderUrlSabor =
  process.env.REACT_APP_API_DOWNLOADER_SABOR ?? "https://localhost:7002";
const agentUrl = process.env.REACT_APP_API_AGENT ?? "https://localhost:7056";

export const fetchPropertyDetails = async (
  mlsNumber: string,
  market: MarketType
): Promise<PropertyDetails | null> => {
  const apiUrl = `${migrationUrl}api/sale-listings/public`;
  try {
    const response = await axios.get<PropertyDetails>(apiUrl, {
      params: { mlsNumber, market },
    });
    if (Array.isArray(response.data) && response.data.length > 0) {
      return response.data[0];
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
export const fetchAgentDetails = async (
  mlsId: string,
  market: MarketType
): Promise<AgentResponse | null> => {
  const isSanAntonio = market == MarketType.SanAntonio;
  const apiUrl = isSanAntonio
    ? `${downloanderUrlSabor}api/search/agent`
    : `${downloanderUrl}api/search/agent`;
  const params = isSanAntonio
    ? { mlsId }
    : {
        mlsId,
        market: marketTypeToMarketCode.get(Number(market) as MarketType),
      };
  try {
    const response = await axios.get<AgentResponse>(apiUrl, { params });
    return response.data;
  } catch (error) {
    return null;
  }
};
export const sendPropertyDetailsByEmail = async (
  emailData: AgentNotificationRequest
): Promise<boolean> => {
  const apiUrl = `${migrationUrl}api/agents/send-listing`;
  try {
    await axios.post(apiUrl, emailData);
    return true;
  } catch (error) {
    return false;
  }
};
export const logAgentService = async (
  data: AgentNotificationRequest
): Promise<boolean> => {
  const apiUrl = `${agentUrl}api/agent-logs`;
  try {
    const response = await axios.post(apiUrl, data);
    return response.status === 200;
  } catch (error) {
    return false;
  }
};
