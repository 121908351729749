import { SxProps } from "@mui/system";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    px: 10,
    py: 2,
    gap: 10,
  },
  detailsBox: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "8px",
    p: 2,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "820px",
    justifyContent: "center",
    alignItems: "center",
  },
  imageBox: {
    display: "flex",
    justifyContent: "center",
    mb: 2,
  },
  imgStyle: {
    width: "100%",
    height: "auto",
    maxHeight: "300px",
    maxWidth: "250px",
    objectFit: "cover",
  },
  infoBox: {
    p: 2,
    color: "#333",
  },
  agentBox: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    pt: 2,
  },
  textFieldStyle: {
    backgroundColor: "white",
  },
  buttonStyle: {
    backgroundColor: "#08f",
    "&:disabled": {
      backgroundColor: "#d3d3d3",
    },
    textTransform: "none",
  },
  titleStyle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#012b5b",
    mb: 2,
  },
};

export default styles;
