export const getOptionsFromEnumAndMap = <
  Tenum extends string | number,
  TLabelMap extends Map<Tenum, string>
>(
  enumType: Tenum[],
  labelMap: TLabelMap
): Record<Tenum, string> => {
  const result: Record<Tenum, string> = {} as Record<Tenum, string>;

  enumType.forEach((value) => {
    const label = labelMap.get(value);
    if (label) {
      result[value] = label;
    }
  });

  return result;
};

export const formatValue = (
  value: number | undefined,
  type: string | undefined
): string => {
  if (value === undefined || type === undefined) {
    return "N/A";
  }

  if (type === "%") {
    return value > 0 ? `${value.toFixed(2)}%` : `${value}%`;
  }

  return `$${value}`;
};
