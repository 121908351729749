import React from "react";
import { Box, Typography, TextField, Button, Grid } from "@mui/material";
import { AgentContext } from "core/contexts";
import { MarketType } from "core/enum";
import { AgentDetails, BackButton } from "core/components";
import styles from "./property-details.styles";
import { ErrorMessage } from "core/components/error-message";
import { PropertyInfo } from "./property-info.component";

const PropertyDetailsPage: React.FC = () => {
  const {
    propertyDetails,
    setMlsNumber,
    setMarket,
    fetchAgentDetails,
    market,
    agentDetails,
    setAgentDetails,
  } = React.useContext(AgentContext) || {};
  const [agentLicense, setAgentLicense] = React.useState("");
  const [error, setError] = React.useState(false);

  const handleSearchAgent = async (): Promise<void> => {
    if (fetchAgentDetails && market) {
      const success = await fetchAgentDetails(
        agentLicense,
        market as MarketType
      );
      if (success) {
        setError(false);
      } else {
        setError(true);
      }
    }
  };

  const handleBack = (): void => {
    if (setMlsNumber && setMarket) {
      setMlsNumber("");
      setMarket("");
    }
  };
  React.useEffect(() => {
    if (setAgentDetails) {
      setAgentDetails(null);
    }
  }, []);
  return (
    <Box sx={styles.container}>
      <Box sx={styles.detailsBox}>
        {propertyDetails ? (
          <>
            <Box sx={styles.infoBox}>
              <Typography variant="h6" sx={styles.titleStyle}>
                Listing Information
              </Typography>
              <Grid container spacing={1} justifyItems={"end"}>
                <PropertyInfo />
                <Grid item xs={12}>
                  <Box sx={styles.agentBox}>
                    {!agentDetails && (
                      <>
                        <Typography
                          sx={{ mb: 0, color: "#012b5b", fontWeight: "bold" }}
                        >
                          Enter your Agent MLS ID to see commissions and seller
                          concessions
                          <br />
                          If an incorrect MLS listing number was entered, Cancel
                          to search for another listing
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          alignItems={"center"}
                          display={"flex"}
                          justifyContent={"center"}
                        >
                          <Grid item xs={7}>
                            <TextField
                              fullWidth
                              label="MLS ID"
                              placeholder="Enter MLS ID"
                              variant="outlined"
                              value={agentLicense}
                              onChange={(e): void =>
                                setAgentLicense(e.target.value)
                              }
                              sx={styles.textFieldStyle}
                            />
                          </Grid>
                          <Grid item xs={3} gap={1} columnGap={2}>
                            <Button
                              variant="contained"
                              sx={styles.buttonStyle}
                              onClick={handleSearchAgent}
                              disabled={!agentLicense}
                            >
                              Ok
                            </Button>
                            <BackButton onBack={handleBack} />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid container>
                      {error ? (
                        <Box sx={{ mt: 2, width: "85%" }}>
                          <ErrorMessage />
                        </Box>
                      ) : (
                        agentDetails && (
                          <AgentDetails agentData={agentDetails} />
                        )
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <Typography>No property details available</Typography>
        )}
      </Box>
    </Box>
  );
};

export default PropertyDetailsPage;
