import { AgentResponse } from "core/interfaces/agent-details.interface";
import { AgentNotificationRequest } from "core/interfaces/agent-notification-request.interface";
import { PropertyDetails } from "core/interfaces/property-details.interface";
import { formatValue } from "./utils";

export const mapToAgentRequestData = (
  propertyDetails: PropertyDetails | null,
  agentData: AgentResponse
): AgentNotificationRequest => {
  return {
    mlsNumber: propertyDetails?.mlsNumber ?? "",
    zip: propertyDetails?.zip ?? "",
    city: propertyDetails?.city ?? "",
    address: propertyDetails
      ? `${propertyDetails.streetNumber} ${propertyDetails.streetName}`
      : "",
    agentEmail: agentData.email ?? "",
    agentName: agentData.fullName ?? "",
    agentCommission:
      propertyDetails && propertyDetails.agentCommission
        ? formatValue(
            propertyDetails.agentCommission,
            propertyDetails.agentCommissionType
          )
        : "N/A",
    sellerConcession:
      propertyDetails && propertyDetails.sellerConcession
        ? formatValue(
            propertyDetails.sellerConcession,
            propertyDetails.sellerConcessionType
          )
        : "N/A",
  };
};
