import React from "react";
import { Box, Typography } from "@mui/material";
import { styles } from "./error-message.styles";

export function ErrorMessage(): JSX.Element {
  return (
    <Box sx={styles.container}>
      <Typography variant="h6">
        The MLS ID cannot be found, one of following may be the reason.
      </Typography>
      <Box component="ul" sx={styles.list}>
        <Typography component="li" variant="body1">
          An incorrect MLS ID may have been entered, please check and reenter.
        </Typography>
        <Typography component="li" variant="body1">
          You are not be a member of the MLS in which the home is listed.
        </Typography>
        <Typography component="li" variant="body1">
          If you are a member of the MLS in which the listing appears, please
          check to be sure your MLS profile is updated.
        </Typography>
      </Box>
    </Box>
  );
}
