import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import PropertyDetailsPage from "modules/property/pages/property-details/property-details.component";

const Home = React.lazy(() => import("modules/home/pages/home/home.component"));

function AppRoutes(): JSX.Element {
  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/property-details" element={<PropertyDetailsPage />} />
        <Route path="*" element={<p>not found</p>} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
